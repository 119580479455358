.my-tabs {
  display: inline-block;
  min-height: 52px;
  background: #818FA6;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 16px;
  min-width: 160px;
  max-width: 446px;
  padding: 12px 10px;
  text-align: center;
  color: white;
  white-space: pre-line;
  font-size: 15px;
  font-weight: bold;
  font-family: var(--second-fond);
}

@media screen and (max-width: 700px) {

  .my-tabs {
    max-width: 85vw;
  }

}
