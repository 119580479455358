#leaveStoryModal .modal-content {
  border-radius: 40px;
  height: 500px;
  width: 672px;
}

#leaveStoryModal  .modal-h1 {
  margin-top: 20px;
}

#leaveStoryModal .modal-dialog {
  align-self: center;
  width: 100%;
}

@media(max-width: 750px) {

  #leaveStoryModal .modal-dialog {
    width: 100%;
    margin-left: 5vw;
  }

  #leaveStoryModal .modal-content {
    width: 90vw;
  }

}

@media(max-height: 570px) {

  #leaveStoryModal .modal-content {
    height: 90vh;
  }

  #leaveStoryModal p {
    font-size: 17px;
  }

  #leaveStoryModal img {
    height: 160px;
    width: 170px;
  }

}
