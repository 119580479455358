#storyOverlay {
  position: absolute;
  z-index: 98;
  height: 80vh;
  width: 100vw;
  animation: colorchange-overlay 6s ease-in-out;
  -webkit-animation: colorchange-overlay 6s ease-in-out;
}

@keyframes colorchange-overlay {
  0%    {background-color: rgb(0,0,0);}
  80%  {background-color: transparent;}
}

@-webkit-keyframes colorchange-overlay {
  0%    {background-color: rgb(0,0,0);}
  80%  {background-color: transparent;}
}

@media(max-width: 715px) {

  #storyOverlay {
    height: 100vh;
  }

}
