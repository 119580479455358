.typeWriter {
  text-align: center;
  white-space: pre-line;
  overflow: auto;
  align-items: center;
  opacity: 0;
  animation: appear 5s forwards;
}

@media (max-width: 768px) {
  .typeWriter {
    max-width: 350px; 
    margin: 0 auto; 
  }

  .question-text {
    font-size: 19px;
    line-height: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .typeWriter {
    margin: 0 auto; 
  }
}

@media (min-width: 1024px) {
  .typeWriter {
    width: 600px; 
    margin: 0 auto;
  }
}

@keyframes disapear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes appear {
  from {
    opacity: 0; 
  }
  to {
    opacity: 1; 
  }
}

.typeWriter::-webkit-scrollbar {
  display: none;
}

.typeWriter .invisible {
  color: transparent;
}
