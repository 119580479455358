.premium-label-wrapper b {
  position: relative;
  right: 25px;
  color: black;
  line-height: 27px;
}

.priceToken {
  height: 27px;
}

.answerScale {
  transform: scale(1.1);
}

.paidBtnSelected {
  position: relative;
  z-index: 99;
}

.my-tabs-premium {
  min-height: 52px;
  background: #D4B026;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 16px;
  min-width: 160px;
  max-width: 446px;
  padding: 12px 10px;
  color: white;
  white-space: pre-line;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-family: var(--second-fond);
}

.notPaidAnswer {
  margin-bottom: 15px;
}

.my-tabs-premium span {
  margin: 0 10px;
}

.premium-label-wrapper {
  position: absolute;
  top: 80%;
  display: flex;
  justify-content: center;
}

.premium-label {
  background: #F4CC32;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 26px;
  width: auto;
  display: flex;
  justify-content: center;
  /*position: absolute;*/
  /*bottom: -25%;*/
  /*left: 25%;*/
}

.premium-text {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}

.paid-btn-span {
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}

.paid-btn-span:before, .paid-btn-span:after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.paid-btn-span:before {
  display: none;
  top: -75%;
  background-image: radial-gradient(circle, red 10%, transparent 10%), radial-gradient(circle, transparent 20%, red 20%, transparent 30%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, transparent 10%, red 15%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.paid-btn-span:after {
  display: none;
  bottom: -75%;
  background-image: radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, transparent 10%, red 15%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%), radial-gradient(circle, red 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.paid-btn-span.active {
  transform: scale(1);
}

.alreadyPaid:active,
.paid-btn-span:active,
.paid-btn-span-true {
  transform: scale(0.9);
}

.paid-btn-span.active:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.paid-btn-span.active:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@media screen and (max-width: 700px) {

  .my-tabs-premium {
    max-width: 85vw;
  }

}

/* MODAL STYLES */

#paidAnswersModal .modal-content {
  border-radius: 40px;
  height: 250px;
  display: flex;
  padding: 25px;
  flex-direction: column;
  align-items: center;
  font-family: var(--main-font);
  text-align: center;
  color: var(--brand-blue);
  font-size: 22px;
}

#paidAnswersModal .modal-content .close {
  align-self: flex-start;
  margin-bottom: 50px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 3px rgba(26,28,63,0.4);
  background-color: white;
}
