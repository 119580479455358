.slides-buttons-container {
  padding: 25px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.higherAnswersContainer {
  min-height: 262px;
}

.answerBtnStub {
  height: 52px;
  width: 100px;
}

@media(max-width: 915px) {

  .slides-buttons-container {
    width: 100vw;
    height: 100vw;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 100px;
  }

}

.slides-buttons-container .slides-btn {
  position: relative;
}

.slides-buttons-container .slides-btn:nth-child(even) {
  margin: 20px 0;
}

.slides-buttons-container .slides-btn:last-child {
  margin-bottom: 0;
}

.slides-buttons-container .slides-btn:hover {
  cursor: pointer;
  filter: drop-shadow(0px 6px 6px rgba(26,28,63,0.2));
}

.slides-buttons-container .free-btn:active {
  transform: scale(0.9);
}

.slides-buttons-container .slides-btn:nth-child(1) {
  animation: slides-btn-animation 2s linear;
  -webkit-animation: slides-btn-animation-1 2s linear;
}

@keyframes slides-btn-animation-1 {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

@-webkit-keyframes slides-btn-animation-1 {
  0%   {opacity: 0;}
  100% {opacity: 1;}
}

.slides-buttons-container .slides-btn:nth-child(2) {
  animation: slides-btn-animation-2 4s linear;
  -webkit-animation: slides-btn-animation-2 4s linear;
}

@keyframes slides-btn-animation-2 {
  0%   {opacity: 0;}
  25%   {opacity: 0;}
  100% {opacity: 1;}
}

@-webkit-keyframes slides-btn-animation-2 {
  0%   {opacity: 0;}
  25%   {opacity: 0;}
  100% {opacity: 1;}
}

.slides-buttons-container .slides-btn:nth-child(3) {
  animation: slides-btn-animation-3 4s linear;
  -webkit-animation: slides-btn-animation-3 4s linear;
}

@keyframes slides-btn-animation-3 {
  0%   {opacity: 0;}
  50%   {opacity: 0;}
  100% {opacity: 1;}
}

@-webkit-keyframes slides-btn-animation-3 {
  0%   {opacity: 0;}
  50%   {opacity: 0;}
  100% {opacity: 1;}
}

@media(max-width: 915px) {

}
