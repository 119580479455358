.profile-imagelogin {
  margin-top: 40px;
  width: 192px;
  height: 41px;
}

.containerLogin {
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-headerLogin {
  margin-bottom: 34px;
  margin-top: 50px;
}

.containerLogin .form-inpu {
  margin-bottom: 32px;
  padding-left: 20px;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  width: 320px;
  height: 52px;
  border-radius: 26px;
  background-color: #ffffff;
  border: 1px solid #e4eeff;
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.containerLogin .form-inpu::placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.containerLogin .form-inpu::-webkit-input-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.containerLogin .form-inpu::-moz-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.containerLogin .form-inpu:-ms-input-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.containerLogin .form-inpu:-moz-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 500;
}

.form-inpu:focus {
  outline: 0;
}

.containerLogin .form-bodyLogin {
  display: flex;
  flex-direction: column;
}

.sing-in-page-btn {
  box-shadow: var(--btn-drop-shadow);
  position: relative;
  background-color: var(--brand-orange);
  width: 320px;
  height: 52px;
  line-height: 52px;
  border-radius: 26px;
  font-size: 16px;
  color: white;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
  margin-bottom: 15px;
}

.sing-in-page-btn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.sing-in-page-btn:active {
  transform: scale(0.9);
}

.upload-bt:active {
  transform: scale(0.9);
}

.containerLogin .forgot-password {
  margin-top: 20px;
  font-size: 13px;
  letter-spacing: 0px;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
}

.containerLogin .forgot-password a {
  color: #818fa6;
}

.closeBtnWrapperLogin {
  padding: 10px 0 0 20px;
  width: 100vw;
}

.closeBtnLogin {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: var(--btn-drop-shadow);
  background-color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.closeBtnLogin:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

@media (max-width: 750px) {

  .closeBtnWrapperLogin {
    padding: 10px 0 0 10px;
    width: 100vw;
  }

  .form-headerLogin {
    margin-top: 15px;
  }
}

@media (max-width: 375px) {

  .containerLogin {
    height: 100vh;
  }

  .containerLogin .closeBtnWrapper {
    top: 0;
  }

  .containerLogin .form-inpu,
  .containerLogin .sing-in-page-btn {
    width: 285px;
  }
}
