.topContentQuestions {
  padding: 2em 6vw 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
}

.topContentQuestions a {
  text-decoration: none;
}

.topContentQuestions .header-tickets {
  margin-right: 20px;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1); 
  }
  50% {
    transform: scale(1.2); 
  }
  100% {
    transform: scale(1); 
  }
}

.first-tokens{
  animation: scaleAnimation 2s forwards;
  animation-delay: 1s;
}

.header-tickets-story {
  height: 45px;
  margin-right: 30px;
  line-height: 45px;
  min-width: 61px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 61px 45px;
  color: var(--brand-orange);
  font-size: 23px;
  font-weight: 700;
  font-family: var(--main-font);
}

@media only screen and (max-width: 750px) {

  .header-tickets-story {
    background-size: 44px 32px;
    font-size: 18px;
    height: 32px;
    line-height: 32px;
    min-width: 44px;
    animation: scaleAnimation 2s forwards;
    animation-delay: 1s;
  }

  .one-digit-padding {
    padding-left: 13px;
  }

  .two-digits-padding {
    padding-left: 9px;
  }

  .topContentQuestions {
    position: absolute;
    width: calc(100vw - 52px);
    padding: 10px 0 0 0;
  }

  .header-tickets-story {
    margin: 0;
  }

  .topContentQuestions .btnBook {
    display: none;
  }

  .footer2 {
    margin: auto;
    display: flex;
    flex-direction: column;
  }
  .fullEmail {
    margin: 0 auto 0 auto;
  }
  .navLinks {
    margin: auto;
    padding-right: 0;
    text-align: center;
  }
  .navList {
    width: 98px;
    height: 25vh;
    margin: 72px auto 64px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navList a {
    text-decoration: none;
  }
  .footerRight {
    margin: 0 56px;
    justify-content: space-between;
  }
  .roundLinks {
    margin-left: 0;
  }
}
