.BuyTickesFlashPage {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}
.BuyTickesFlashPage .header img {
  position: relative;
  bottom: 10px;
  height: 41px;
}

.topBuyContent h2 {
  font-size: 22px;
  line-height: 24px;
  color: var(--brand-blue);
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.buyCloseBtn {
  margin-left: 20px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: var(--btn-drop-shadow);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyCloseBtn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.ticketsContent {
  display: flex;
  flex-direction: column;
  height: 52vh;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
}

.ticketsAmount {
  color: var(--brand-orange);
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.ticketsPrice {
  line-height: 24px;
  color: var(--brand-blue);
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.bigTicket {
  width: 200px;
  height: 145px;
  line-height: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px 145px;
  margin-bottom: 30px;
}

.bigTicket .ticketsAmount {
  font-size: 80px;
}

.bigTicket .ticketsPrice {
  font-size: 20px;
}

.smallTicketsContainer {
  display: flex;
}

.smallTicket {
  height: 85px;
  min-width: 112px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 112px 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallTicket .ticketsAmount {
  font-size: 35px;
  line-height: 1;
  margin-bottom: 0;
}

.smallTicket .ticketsPrice {
  font-size: 13px;
  margin-bottom: 0;
}

.smallTicket:hover {
  filter: drop-shadow(0px 6px 10px rgba(242,93,15,0.6));
  cursor: pointer;
}

.buyBtn {
  align-self: center;
}

.buyBtn button {
  position: relative;
  font-family: var(--main-font);
  color: var(--brand-blue);
  font-style: italic;
  font-weight: bold;
  font-size: 29px;
  width: 196px;
  height: 52px;
  border-radius: 26px;
  background-image: linear-gradient(180deg, #f8b000 0%, #ff6100 100%);
  border: 2px solid #9b4700;
}

.buyBtn button:active {
  transform: scale(0.9);
}

.buyBtn button:hover {
  background-image: linear-gradient(180deg, #ff6100 0%, #f8b000 100%);
}

.BuyTicketsPayment {
  height: 80vh;
}

.BuyTicketsPayment .header img {
  height: 41px;
}

.stripeContainer {
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stripeContainer form {
  width: 50%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cardElementWrapper {
  width: 80%;
  padding: 40px 10px 0 15px;
  background-color: white;
  height: 100px;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 26px;
}

.cardElementWrapper:hover,
.cardElementWrapper:focus {
  box-shadow: 0px 8px 12px rgba(26, 28, 63, 0.2);
}


@media screen and (max-width: 625px) {

  .BuyTickesFlashPage {
    height: 90vh;
  }

  .topBuyContent {
    position: relative;
    bottom: 40px;
    flex-direction: column;
  }

  .buyCloseBtn {
    margin-bottom: 20px;
  }

  .bigTicket {
    width: 160px;
    height: 120px;
    line-height: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 160px 120px;
  }

  .BuyTickesFlashPage .header {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .BuyTickesFlashPage .header img {
    height: 36px;
  }

  .bigTicket .ticketsAmount {
    font-size: 60px;
  }

  .bigTicket .ticketsPrice {
    font-size: 18px;
  }

  .smallTicket {
    height: 79px;
    min-width: 104px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 104px 79px;
  }

  .smallTicket .ticketsAmount {
    font-size: 25px;
  }

  .smallTicket .ticketsPrice {
    font-size: 13px;
  }

  .BuyTicketsPayment .stub img {
    height: 250px;
  }

}
