.starFeedback span {
  margin-right: 10px;
}

.starMain span {
  margin-right: 6px;
}

@media only screen and (max-width: 900px) {

  .starMain span {
    margin-right: 3px;
  }

}

.starFeedback span[data-index='4'] {
  margin: 0;
}
