footer {
  height: 20vh;
  background-color: var(--brand-light-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 1910px) {

  footer {
    padding: 0 430px 0 430px;
  }

}

@media screen and (min-width: 1701px) and (max-width: 1909px) {

  footer {
    padding: 0 117px 0 117px;
  }

}

@media screen and (min-width: 1422px) and (max-width: 1700px) {

  footer {
    padding: 0 117px 0 177px;
  }

}

@media screen and (min-width: 1441px) and (max-width: 1477px) {

  footer {
    padding: 0 117px 0 137px;
  }

}

@media screen and (min-width: 1418px) and (max-width: 1440px) {

  footer {
    padding: 0 117px 0 117px;
  }

}

@media screen and (min-width: 1398px) and (max-width: 1417px) {

  footer {
    padding: 0 107px 0 107px;
  }

}

@media screen and (min-width: 1268px) and (max-width: 1397px) {

  footer {
    padding: 0 117px 0 237px;
  }

}

@media screen and (min-width: 1155px) and (max-width: 1267px) {

  footer {
    padding: 0 117px 0 147px;
  }

}

@media screen and (min-width: 1122px) and (max-width: 1154px) {

  footer {
    padding: 0 117px 0 117px;
  }

}

@media screen and (min-width: 1088px) and (max-width: 1121px) {

  footer {
    padding: 0 100px 0 100px;
  }

}

@media screen and (min-width: 1068px) and (max-width: 1087px) {

  footer {
    padding: 0 90px 0 90px;
  }

}

@media screen and (min-width: 1048px) and (max-width: 1067px) {

  footer {
    padding: 0 80px 0 80px;
  }

}

@media screen and (min-width: 1008px) and (max-width: 1047px) {

  footer {
    padding: 0 60px 0 60px;
  }

}

@media screen and (min-width: 968px) and (max-width: 1007px) {

  footer {
    padding: 0 40px 0 40px;
  }

}

@media screen and (min-width: 951px) and (max-width: 967px) {

  footer {
    padding: 0 20px 0 20px;
  }

}

@media screen and (min-width: 888px) and (max-width: 950px) {

  footer {
    padding: 0;
  }

}

.footerLeft {
  width: 40vw;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
}

.footerLeft a {
  text-decoration: none;
}

.footerRight {
  display: flex;
  align-items: center;
}

/* Mobile Styles */
.fullEmail {
  max-width: 308px;
  max-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 26px;

}

.fullEmailOrange {
  width: 128px;
  height: 44px;
  background: #F25D0F;
  border-radius: 26px;

}

.fullEmailText {
  padding: 10px 0 10px 10px;
  width: 180px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 26px;
  text-align: start;
  font-family: var(--main-font);
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #818FA6;
  border: none;
  outline: none;
}
/* End Mobile Styles */

.navFirst {

}

.navLinks {

  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #818FA6;
}

.navLinks:hover {
  text-decoration: underline;
}

.roundLinks {
  width: 44px;
  height: 44px;
  margin-left: 32px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: var(--btn-drop-shadow);
}

.roundLinks:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.roundLinks>img {
  margin: auto;
}

@media only screen and (max-width: 1230px){
  .footer{
    padding: 0 10px;
    justify-content: space-evenly;
  }
  .navLinks{
    padding-right: 15px;
  }
  .navFirst{

  }
  .roundLinks{
    margin-left: 10px;
  }
  .fullEmail {
    max-width: 170px;
  }

  .fullEmailOrange {
    width: 70px;
  }

  .fullEmailText {
    width: 100px;
  }
}

@media (max-width: 800px) {
  .navFirst{

  }
}

@media (max-width: 824px) {
  footer{
    display: none;
  }

  .btn{
    height: 44px;
  }
  .btnBook {
    width: 56px;
    height: 44px;
    margin: 0;
  }
}
