.login-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container .logo {
  height: 50px;
}

.body-container {
  display: flex;
  height: 35vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.loginPageText {
  font-size: 24px;
  color: var(--brand-blue);
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
  margin: 20px 0;
}

.loginAdditionalText {
  font-size: 20px;
  font-family: var(--main-font);
  color: var(--red);
  font-weight: 600;
}

.loginButton {
  position: relative;
  width: 320px;
  height: 52px;
  border-radius: 26px;
  box-shadow: var(--btn-drop-shadow);
}

.loginButton:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
  color: #ffffff;
  text-decoration: none;
}

.loginButton:active {
  transform: scale(0.9);
}

.facebookLogin {
  background-color: #1e4fad;
  border: 1px solid #e4eeff;
}

.googleLogin {
  background-color: #ffffff;
  border: 1px solid #e4eeff;
}

.emailLogin {
  background-color: var(--brand-orange);
  text-decoration: none !important;
  line-height: 52px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
}

@media screen and (max-width:870px) {

  .login-container {
    height: 100vh;
  }

  .header {
    margin-bottom: 100px;
  }

  .body-container {
    height: 50vh;
  }

  .loginButton {
    width: 75vw;
  }

}

@media only screen and (max-height: 819px) {
  .loginButton {
    min-height: 52px;
  }

  .body-container {
    height: 50vh;
  }
}
