.theEnd {
  background-color: #F7FAFF;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.theEndBtnscontainer {
  display: flex;
  margin-top: 40px;
}

.theEndBtnscontainer .continueButton {
  width: 220px;
}

.theEndBtnscontainer .continueButton:first-child {
  margin-right: 50px;
}

.btun{
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 36px;
  color: white;
  width: 192px;
  height:52px;
  background-color: var(--brand-orange);
  border: 1px solid white;
  border-radius: 26px;
}

.theEndText {
  line-height: 36px;
  color: #181b3f;
  font-family: var(--main-font);
  text-align: center;
}

.theEndText.big {
  font-size: 20px;
  font-weight: 900;
}

.theEndText.small {
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (min-width: 426px) {
 .btun:first-child {
   margin-right: 30px;
 }
}

.theEndImgWrapper {
  height: 392px;
}

@media only screen and (max-width: 625px){

  .theEndBtnscontainer .continueButton:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .theEnd {
    height: 100vh;
  }

  .theEndImgWrapper {
    height: 370px;
  }

  .theEnd img {
    height: 370px;
  }

  .theEndBtnscontainer {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .btun {
    margin: 0;
  }

  .theEndText.big {
    font-size: 16px;
  }

  .theEndText.small {
    font-size: 14px;
  }

}

@media only screen and (max-height: 819px) {

  .theEndImgWrapper {
    height: 300px;
  }

  .theEnd img {
    height: 300px;
  }

}

@media only screen and (max-width: 340px) {

  .theEndImgWrapper {
    height: 210px;
  }

  .theEnd img {
    height: 210px;
  }

}
