.overlayWrapper {
  height: 80vh;
  background-color: transparent;
}

@media(max-width: 750px) {

  .fullContentWrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

}

.fullContentWrapper {
  z-index: 10;
  height: 100vh;
  background-repeat: no-repeat;
}


.blackFullContentWrapper {
  background-color: black;
}

.blueFullContentWrapper {
  background-color: rgb(5,44,73);
}

.whiteFullContentWrapper {
  background-color: rgb(255,255,255);
}

.btnBook:focus {
  outline: none;
}

@media(max-width: 715px) {

  .fullContentWrapper {
    overflow: hidden;
    background-image: none !important;
    justify-content: space-between;
    height: calc(100% - 76px);
  }

  .overlayWrapper {
    background-color: transparent;
    height: 100vh;
  }

}
