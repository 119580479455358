.questionsFooter{
    display: none;
}

.btnB-stub {
  padding: 22px;
}

@media only screen and (max-width: 700px){

  .questionsFooter{
    position: absolute;
    z-index: 399;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 76px;
    background-color: white;
    box-shadow: 0px -2px 14px rgba(26, 28, 63, 0.2);
    padding: 0 8px;
  }

}
