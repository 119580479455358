.btnBook {
  position: relative;
  width: 64px;
  height: 52px;
  margin-right: 36px;
  background-color: white;
  border: 1px solid #E4EEFF;
  box-shadow: var(--btn-drop-shadow);
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.btnBook:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.btnBook:active {
  transform: scale(0.9);
}

@media only screen and (max-width: 950px) {


}
