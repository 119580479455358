/*HEADER LOGO FOR PROFILE, PRIVACY, FAQ*/
.header {
  margin: 24px 10vw;
}

.fullContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 80vh;
}

@media (max-width: 824px) {

  .fullContent {
    min-height: 100vh;
  }

}

.main-header {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1440px) {

  .main-header {
    width: calc(100% - 20px);
  }

}

@media (min-width: 1441px) and (max-width: 1910px) {

  .main-header {
    width: 100%;
  }

}

@media (min-width: 1911px) {

  .main-header {
    width: 100%;
    padding: 0 215px 0 215px;
  }

  .main-header-logo {
    margin-left: 0;
  }

}

.main-header a {
  text-decoration: none;
}

.main-header-logo {
  height: 41px;
}

@media only screen and (min-width: 950px) {

  .main-header-logo {
    margin-left: 10px;
  }

  .main-header-stub {
    margin-right: 10px;
  }

  .header-tickets {
    margin-right: 10px;
  }

}

.main-header-stub {
  display: none;
}

.header-tickets {
  height: 45px;
  line-height: 45px;
  min-width: 61px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 61px 45px;
  color: var(--brand-orange);
  font-size: 23px;
  font-weight: 700;
  font-family: var(--main-font);

}

.first-tokens{
  animation: scaleAnimation 2s forwards;
  animation-delay: 1s;
}

.one-digit-padding {
  padding-left: 19px;
}

.one-digit-padding-mobile {
  padding-left: 13px;
}

.two-digits-padding {
  padding-left: 13px;
}

.two-digits-padding-mobile {
  padding-left: 9px;
}

.main-header-stub-desktop {
  display: block;
  height: 45px;
  line-height: 45px;
  min-width: 61px;
}

.tickets-red {
  color: red;
}

.main-header-download-link {
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: auto;
  align-items: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #818fa6;
  background-color: transparent;
  font-weight: 800;
  font-family: var(--main-font);
}

.downloadLinkPlaceholder {
  height: 41.5px;
  width: 39.69px;
}

.arrow-down {
  height: 20px;
  width: 20px;
}

.header-tickets-mobile-wrapper {
  display: none;
}

.navbar {
  position: absolute;
  z-index: 123;
  top: 0;
  left: 0;
  background: var(--brand-light-blue);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
  /* hide the menu above the screen by default */
  transform: translateY(-100%);
  /* transition adds a little animation when sliding in and out the menu */
  transition: transform 0.2s ease;
}

@media only screen and (min-width: 825px){
  .ham {
    top: 0;
    display: none;
  }

  .navbar {
    display: none;
  }
}

@media only screen and (max-width: 826px){
  .ham {
    box-shadow: var(--btn-drop-shadow);
    top: 10px;
  }

}

.ham {
  position: absolute;
  z-index: 1000;
  right: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #fff;

  cursor: pointer;
  background-color: white;
  transform: translateY(-100%);
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
}

.ham:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

@media only screen and (max-width: 824px) {

  /* change ham image to close */
  .showClose {
    background-image: url("https://upload.wikimedia.org/wikipedia/commons/a/a0/OOjs_UI_icon_close.svg");
  }

  .showNav,
  .ham {
    transform: translateY(0);
  }

}

.main-header-stub-mobile {
  display: none;
}

@media only screen and (max-width: 1029px) {

  .main-header {
    width: 90vw;
  }

}

@media only screen (min-width: 825px) and (max-width: 886px) {

  .main-header {
    width: 97vw;
  }

}

@media only screen and (max-width: 750px){

  .main-header {
    width: 100%;
    max-height: 50px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .main-header-logo {
    height: 28px;
    width: 132px;
    order: 2;
  
  }

    .main-header-download-link {
      order: 1;
      margin-left: 0;
    }

    .header-tickets-mobile-wrapper {
      display: block;
      margin-right: 40px;
    }

    .inp {
      order: 5;
    }

    .header-tickets-mobile {
      height: 32px;
      line-height: 32px;
      min-width: 44px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 44px 32px;
      color: var(--brand-orange);
      font-size: 18px;
      font-weight: 700;
      font-family: var(--main-font);
    }

    .main-header-stub-mobile {
      display: block;
      height: 32px;
      line-height: 32px;
      min-width: 44px;
    }

    .main-header-stub-desktop {
      display: none;
    }

    .recent-popular-switch,
    .header-tickets {
      display: none;
    }

    .main-headerTopPanel :first-child {
      width: 16px;
      height: 16px;
      margin-left: 4px;
      margin-right: 90px;
    }

    .fullContent {
      padding: 13px 8px 8px 8px!important;
      height: auto;
    }

    .topContent{
        display: block;
    }

}



@media only screen and (max-width: 350px){
  .main-header-logo {
    height: 30px;
  }
}
