#pwaPopUpModal .modal-content {
  width: 100vw;
  /* max-width: 100vw; */
  padding: 32px 8px 43px 8px;
  height: 100vh;
  /* border-radius: 34px; */
  background-color: var(--brand-orange);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.modal-dialog {
  margin: 0;
  height: 100%;
  max-width: 100%;
}

.pwaVideo {
  max-height: 400px;
  width: 400px;
}

#pwaPopUpModal .pwaModalHeader {
  padding: 0 24px 0 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#pwaPopUpModal .pwaModalHeader .close {
  z-index: 999;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: 0px 4px 3px rgba(26,28,63,0.4);
  background-color: white;
}

#pwaPopUpModal .pwaModalHeader img {
  height: 60px;
}

#pwaPopUpModal .pwaModalHeader .pwaModalStub {
  width: 10px;
}

#pwaPopUpModal .modal-body {
  width: 90vw;
}

#pwaPopUpModal .pwaModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#pwaPopUpModal .pwaModalContent .pwaModalH1 {
  font-size: 34px;
  color: #1a1c3f;
  font-weight: 700;
  font-family: var(--main-font);
  text-align: center;
}

#pwaPopUpModal .PWAPopUpBtn {
  display: flex;
  width: 100%;
  justify-content: center;
}

#pwaPopUpModal .PWAPopUpBtn div {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--main-font);
  text-align: center;
}

.browserIcon {
  margin-top: 49px;
  height: 139px;
  width: 139px;
}

@media screen and (max-width: 750px) {

  #pwaPopUpModal .modal-dialog.modal-dialog-centered {
    width: 100vw;
    /* max-width: 100vw; */
  }

  #pwaPopUpModal .pwaModalHeader img {
    height: 36px;
  }

  #pwaPopUpModal .pwaModalContent video {
    margin-top: 30px;
    width: 90vw;
    height: 365px;
  }

  #pwaPopUpModal .pwaModalContent .pwaModalH1 {
    font-size: 28px;
  }

}

@media screen and (max-width: 801px) {

  #pwaPopUpModal .pwaModalHeader img {
    height: 26px;
  }

  #pwaPopUpModal .modal-dialog.modal-dialog-centered {
    margin-left: 5vw;
  }

}
