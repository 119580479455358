/* MAIN LAYOUT */

.mainContentQuestions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;
  width: 100vw;
  padding: 0 127px;
  margin-top: 12px;
}

@media(min-width: 916px) {

  .mainContentQuestions {
    min-height: 50vh;
  }

}

@media(max-width: 915px) {

  .mainContentQuestions {
    margin-top: 0;
    display: block;
    padding: 0;
    height: calc(100% - 40vh);
  }

}

@media(max-width: 650px) {

  .mainContentQuestions {
    margin-top: 0;
    display: block;
    padding: 0;
    height: 100%;
  }

}

/* CARD GENERAL STYLES */

@media(min-width: 916px) {



}

.contentBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  border-radius: 21px;
  width: 704px;
}

@media(min-width: 750px) {

  .contentDescription {
    max-height: 352px;
  }

}



.contentQuestion {
  min-height: 467px;
  max-height: 500px;
}

.contentBox-dark {
  background-color: rgba(5,44,73,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: #ffffff;
}
.contentBox-light {
  background-color: rgba(255,255,255,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  color: #000000;
}

@media(max-width: 650px) {
  .contentBox-light {
    height: 90vh;
    overflow-y: unset;
  }

  .contentBox-dark {
    height: 90vh;
    overflow-y: unset;
  }

}

@media(max-width: 915px) {

  .contentBox {
    display: block;
    z-index: 1;
    width: 100vw;
    height: 100%;
    border-radius: 0;
  }

  .contentBoxNoScroll {
    overflow-y:hidden;
  }

  .contentBoxScroll {
    overflow-y: scroll;
  }


  .contentBox-dark {
    background-color: rgb(5,44,73);
  }
  .contentBox-light {
    background-color: rgb(255,255,255);
  }

}

/* CARD TEXT STYLES */

.Typewriter {
  text-align: left;
}

.main-text {
  min-height: 100%;
  padding: 36px 63px;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  overflow: hidden;
}

.main-text-dark {
  color: white;
}

.main-text-light {
  color: var(--brand-blue);
}

.question-text {
  padding: 16px 63px 10px 63px;
  font-size: 32px;
  line-height: 39px;
  font-family: var(--second-fond);
  text-align: center;
}

.question-text-dark {
  color: #ffffff;
}

.question-text-light {
  color: var(--brand-blue);
}

@media(max-width: 915px) {

  .main-text {
    padding: 25px 8px;
  }

  .question-text {
    padding: 36px;
    font-family:  "Courier Prime", monospace;
    text-align: center;
  }

}


/* ARROW BUTTONS STYLES */

.btn-stub-left,
.btn-stub-right {
  padding: 25px;
  margin-bottom: 10vh;
}

.mobile-btn-stub-left,
.mobile-btn-stub-right {
  margin-bottom: 0;
}

.desktop-btn-stub-left,
.desktop-btn-stub-right,
.desktop-circle-btn {
  margin-bottom: 10vh;
  align-self: flex-end;
}

.circle-btn {
  box-shadow: var(--btn-drop-shadow);
  position: relative;
  padding: 14px 17px;
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-btn:hover {
  cursor: pointer;
  box-shadow: 0px 6px 6px rgba(26,28,63,0.7);
}

.circle-btn:active {
  transform: scale(0.9);
}

.circle-btn:disabled,
.circle-btn[disabled] {
  background-color: #ecf3ff;
}

.circle-btn:disabled:hover,
.circle-btn[disabled]:hover {
  border: none;
  cursor: pointer;
}

@media(max-width: 915px) {

  .desktop-btn-stub-right,
  .desktop-btn-stub-left,
  .desktop-circle-btn {
    display: none;
  }

}
