.loadingContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loadingContainer img {
  width: 377px;
  height: 80px;
  animation: scale 2s ease-in-out infinite;
}

@keyframes scale {
  0%   {transform: scale(1);}
  20%   {transform: scale(1.1);}
  40%   {transform: scale(1);}
  60%  {transform: scale(1.1);}
  80%  {transform: scale(1.05);}
  100%  {transform: scale(1);}
}

@media screen and (max-width: 930px) {

  .loadingContainer img {
    width: 220px;
    height: 47px;
  }

}
