.reset {
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.266667px;
  text-align: center;
  color: var(--brand-blue);
}

.container-headerReset {
  width: 100%;
  justify-content: center;
  display: flex;
}

.container-headerReset .upload-bt {
  position: relative;
}

.container-headerReset .upload-bt:active {
  transform: scale(0.9);
}

.form-headerReset {
  margin-top: 145px;
  margin-bottom: 11px;
}

#resetPassModal .modal-content {
  padding: 30px;
  height: 160px;
  border-radius: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.resetPswX {
  align-self: flex-end;
}

.modalText {
  font-size: 36px;
  color: var(--brand-blue);
  font-weight: 700;
  font-family: var(--main-font);
  text-align: center;
}
