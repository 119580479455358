.fullContent{
  opacity: 0;
  transition: opacity 1s ease;
}

.loadeBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.mainContent {
  min-height: 52vh;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

@media screen and (min-width: 1910px) {

  .mainContent {
    margin: 0 215px 158px 215px;
  }

}

@media screen and (min-width: 1701px) and (max-width: 1909px) {

  .mainContent {
    margin: 0 117px 158px 117px;
  }

}

@media screen and (min-width: 1422px) and (max-width: 1700px) {

  .mainContent {
    margin: 0 117px 158px 177px;
  }

}

@media screen and (min-width: 1441px) and (max-width: 1477px) {

  .mainContent {
    margin: 0 117px 158px 137px;
  }

}

@media screen and (min-width: 1418px) and (max-width: 1440px) {

  .mainContent {
    margin: 0 117px 158px 117px;
  }

}

@media screen and (min-width: 1398px) and (max-width: 1417px) {

  .mainContent {
    margin: 0 107px 158px 107px;
  }

}

@media screen and (min-width: 1268px) and (max-width: 1397px) {

  .mainContent {
    margin: 0 117px 158px 237px;
  }

}

@media screen and (min-width: 1155px) and (max-width: 1267px) {

  .mainContent {
    margin: 0 117px 158px 147px;
  }

}

@media screen and (min-width: 1122px) and (max-width: 1154px) {

  .mainContent {
    margin: 0 117px 158px 117px;
  }

}

@media screen and (min-width: 1088px) and (max-width: 1121px) {

  .mainContent {
    margin: 0 100px 158px 100px;
  }

}

@media screen and (min-width: 1068px) and (max-width: 1087px) {

  .mainContent {
    margin: 0 90px 158px 90px;
  }

}

@media screen and (min-width: 1048px) and (max-width: 1067px) {

  .mainContent {
    margin: 0 80px 158px 80px;
  }

}

@media screen and (min-width: 1008px) and (max-width: 1047px) {

  .mainContent {
    margin: 0 60px 158px 60px;
  }

}

@media screen and (min-width: 968px) and (max-width: 1007px) {

  .mainContent {
    margin: 0 40px 158px 40px;
  }

}

@media screen and (min-width: 951px) and (max-width: 967px) {

  .mainContent {
    margin: 0 20px 158px 20px;
  }

}

@media screen and (min-width: 888px) and (max-width: 950px) {

  .mainContent {
    margin: 0 0 158px 0;
  }

}

@media screen and (min-width: 840px) and (max-width: 887px) {

  .mainContent {
    margin: 0 100px 158px 140px;
  }

}

@media screen and (min-width: 818px) and (max-width: 839px) {

  .mainContent {
    margin: 0 100px 158px 120px;
  }

}

@media screen and (min-width: 770px) and (max-width: 817px) {

  .mainContent {
    margin: 0 80px 158px 80px;
  }

}

@media screen and (min-width: 700px) and (max-width: 769px) {

  .mainContent {
    margin: 0 7px 158px 7px;
  }

}


.search {
  justify-self: center;
  width: 40vw;
  height: 5vh;
  background: white;
  border: 1px solid #E4EEFF;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 26px;
  margin-left: 15vw;
}

.card {
  box-shadow: var(--btn-drop-shadow);
  border-style: none;
  width: 40vw;
}

.card-body {
  border-style: none;
}

.modal-dialog,
.modal-content {
  height: 70vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  height: 20vh;
}

.my-button {
  position: relative;
  background: var(--brand-orange);
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 26px;
  padding: 16px 24px;
  color: white;
}

.my-button2 {
  background: #ec0505;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  border-radius: 26px;
  padding: 16px 24px;
  color: white;
}

.height-160 {
  height: 160px;
  background: var(--brand-light-blue);
}

.footer {
  position: absolute;
  bottom: 0;
}

.footer-links {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */
  color: #818FA6;
}

.footer-subscription {
  padding-left: 0;
  background: white;
  border-radius: 26px;
}

.footer-subscription-button {
  background: var(--brand-orange);
  border-radius: 26px;
  padding: 10px 20px;
  text-align: center;
  color: white;
}

.footer-social {
  border-radius: 50%;
  background: white;
  padding: 10px 10px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
}

.story-container {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.story-wrapper {
  margin: 10px 8px;
}

.story-description-rating {
  font-size: 19px;
  color: #818fa6;
  font-weight: 300;
  font-family: var(--main-font);
}

.commitsLink {
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.story-text {
  color: var(--brand-blue);
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
}

@media only screen and (max-width: 360px) {
  .mainContent {
    display: flex;
    flex-wrap: wrap;
  }

  .lockStoryOverlayWrapper {
    min-height: 95px;
    min-width: 100px;
  }

  .story {
    min-height: 95px;
    min-width: 100px;
  }
}

@media only screen and (max-width: 726px) {

  .story-wrapper {
    width: 46vw;
    margin: 0;
  }

}

@media only screen and (max-width: 900px) {

  .story-description-rating {
    font-size: 13px;
    margin-right: 8px;
  }

  .commitsLink {
    width: 130px;
  }

  .story-description {
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 583px) {
  .mainContent {
    display: flex;
    flex-wrap: wrap;
  }

  .commitsLink {
    width: 100px;
  }

  .story-text {
    font-size: 13px;
  }

}

.lockStoryOverlayWrapper {
  height: 202px;
  width: 280px;
  border-radius: 25px;
  cursor: pointer;
}

.story {
  position: relative;
  height: 202px;
  width: 280px;
  border-radius: 25px;
  z-index: 90;
  box-shadow: var(--btn-drop-shadow);
}

.story:hover {
  cursor: pointer;
  box-shadow: var(--btn-drop-shadow-onhover);
}

.story-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  width: 280px;
}

@media only screen and (max-width: 769px) {

  .mainContent {
    overflow-x: hidden;
    justify-content: space-between;
  }

  .lockStoryOverlayWrapper {
    width: 46vw;
    height: 33vw;
    border-radius: 20px;
  }

  .story {
    width: 46vw;
    height: 33vw;
    border-radius: 20px;
  }

  .story-description {
    font-size: 12px;
    width: 46vw;
    margin-top: 7px;
    margin-left: 8px;
  }

  .avatarContainer {
    margin: 20px 0;
  }
}

.wayName .input {
  width: 10vw;
}

.main {
  height: 100vh;
}

.flip {
  background-color: none;
  background-image: none;
}

.stars {
  float: right;
}

.commitsModal {
  border-radius: 40px;
  width: 672px;
  border: none;
}

@media only screen and (min-width: 1100px) {

  .commitsModal {
    height: 60vh;
  }

}

.modal-dialog.commitsModalDialog {
  width: 672px;
  max-width: 672px;
  height: 60vh;
}

.commitsModalHeader {
  z-index: 100;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  margin: 37px 32px 0 33px;
}

.commitsModalTitle {
  font-size: 22px;
  color: var(--brand-blue);
  font-weight: 700;
  font-family: var(--main-font);
}

.commitsModalBody {
  width: 672px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 769px) {

  .commitsModalHeader {
    margin: 37px 16px 15px 23px;
  }

  .modal-dialog.commitsModalDialog {
    width: 95vw;
    max-width: 95vw;
    height: 90vh;
  }

  .commitsModal {
    height: 90vh;
    width: 95vw;
  }

  .commitsModalBody {
    width: 95vw;
    overflow-x: hidden;
    overflow-y: scroll;
  }

}

.commitsModalHeaderBtns {
  display: flex;
  align-items: center;
  align-content: center;
}

.modalAddReview {
  width: 116px;
  height: 32px;
  border-radius: 16px;
  background-color: var(--brand-orange);
  font-size: 13px;
  color: #ffffff;
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
  margin-right: 30px;
}

@media only screen and (max-width: 769px) {

  .modalAddReview {
    margin-right: 12px;
  }

}

.modalAddReview:disabled,
.modalAddReview[disabled] {
  background-color: var(--brand-orange-light);
  cursor: default;
}

.modalAddReview:disabled:hover,
.modalAddReview[disabled]:hover {
  cursor: default;
  border: none;
}

.loadMore {
  position: relative;
  background-color: #dcdcdc;
  height: 32px;
  min-height: 32px;
  width: 100%;
  padding: 6px 0;
  line-height: 10px;
  border-radius: 0 0 40px 40px;
  font-size: 15px;
  line-height: 20px;
  color: #8f8f8f;
  font-weight: 500;
  font-family: var(--main-font);
}

.loadMore:disabled,
.loadMore[disabled] {
  cursor: default;
}

.loadMore:disabled:hover,
.loadMore[disabled]:hover {
  cursor: default;
  border: none;
}

.commitDetails {
  flex: 0 1 auto;
  min-height: min-content;
  width: 95%;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-bottom: 40px;
}

.avatarContainer {
  width: 10%;
  margin-right: 20px;
  position: relative;
  bottom: 7px;
}

.commitInfoContainer {
  width: 100%;
}

.commitText {
  font-size: 15px;
  line-height: 20px;
  color: var(--brand-blue);
  font-weight: 500;
  font-family: var(--main-font);
}

.commitText.emptyCommit {
  height: 30px;
}

.commitRating {
  margin-bottom: 15px;
}

.commitRatingMobile {
  display: none;
}

@media only screen and (max-width: 769px) {

  .commitDetails {
    flex-direction: column;
  }

  .avatarContainer {
    margin: 0;
    width: 45%;
    min-width: 140px;
    align-items: center;
    justify-content: space-between;
    display: flex;
  }

  .commitRatingMobile {
    display: block;
  }

  .commitRating {
    display: none;
  }

  .commitText {

  }

}

/* LOCKED STORIES STYLES */
.lockStoryOverlayWrapper {
  position: sticky;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.lockStoryOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(129, 143, 166, 0.7019607843137254);
  border-radius: 25px;
  z-index: 99;
  height: 202px;
  width: 280px;
  overflow: hidden;
}

.unlockedStoryOverlay {
  cursor: pointer;
  position: absolute;
  border-radius: 25px;
  z-index: 99;
  height: 202px;
  width: 280px;
  display: block;
  overflow: hidden;
}

.unlockedStoryOverlay:before,
.unlockedStoryOverlay:after {
  content: '';
  position: absolute;
  top: 0;
  left: -175%;
  width: 175%;
  height: 175%;
  background-color: transparent;
  opacity: .6;
  transform: rotate(75deg);
  transition: all ease 1s;
}

.unlockedStoryOverlay:after {
  transition-delay: .2s;
}

.story.animate,
.unlockedStoryOverlay.animate {
  animation: scale-story 1.2s ease-in-out;
}

@keyframes scale-story {
  0%   {transform: scale(1);}
  50%   {transform: scale(1.1);}
  100%  {transform: scale(1);}
}

.unlockedStoryOverlay.animate:before,
.unlockedStoryOverlay.animate:after {
  left: 120%;
  background-color: white;
}

.notCashedOverlay {
  color: white;
  cursor: default;
}

@media only screen and (max-width: 360px) {
  .lockStoryOverlay {
    min-height: 95px;
    min-width: 100px;
  }

  .unlockedStoryOverlay {
    min-height: 95px;
    min-width: 100px;
  }
}

@media only screen and (max-width: 769px) {
  .lockStoryOverlay {
    width: 46vw;
    height: 33vw;
    border-radius: 20px;
  }

  .unlockedStoryOverlay {
    width: 46vw;
    height: 33vw;
    border-radius: 20px;
  }

}

/* LOCKED STORIES MODAL STYLES */
#lockStoryModal,
#profileDeleteModal,
#continueModal,
#myModal,
#leaveStoryModal,
#resetPassModal,
#pwaPopUpModal,
#paidAnswersModal  {
  background-color: rgba(26, 28, 63, 0.5);
}

.lockStoryModal {
  height: 500px;
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  border-radius: 34px;
  font-family: var(--main-font);
  text-align: center;
  color: var(--brand-blue);
}

.lockStoryModalHeader {
  width: 100%;
  display: flex;
  padding: 0 50px;
  justify-content: center;
  align-items: center;
}

.lockStoryModalHeader img {
  height: 56px;
}

.lockStoryModal h2 {
  font-size: 50px;
  font-weight: 800;
}

.lockStoryModal p {
  font-size: 14px;
  font-weight: 500;
}

.okBtn {
  box-shadow: var(--btn-drop-shadow);
  position: relative;
  width: 96px;
  height: 52px;
  border-radius: 26px;
  background-color: var(--brand-orange);
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
}

.okBtn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.okBtn:active {
  transform: scale(0.9);
}

@media screen and (max-width: 500px) {

  #lockStoryModal  .modal-dialog {
    width: 95vw;
    max-width: 95vw;
  }

  #lockStoryModal .modal-content {
    border-radius: 40px;
    width: 95vw;
    min-height: 400px;
    max-height: 85vh;
  }

  .lockStoryModalHeader {
    padding: 0 10px;
  }

  .lockStoryModalHeader img {
    height: 46px;
  }
}

/*ALERT STYLES*/

.alert-warning {
  position: absolute;
  top: 40px;
  width: 95vw;
  order: 4;
  margin-top: 20px;
  background-color: #FCDECF;
  border-color: var(--brand-orange);
  color: var(--brand-orange);
  z-index: 100;
}
