.ageVerify {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  animation: appear 1s forwards;
}

.ageVerify-disapear {
  animation: disapear 1s forwards;
}

.ageVerifyLogo {
  height: 65px;
}

.ageVerifivationButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 65%;
}

.ageVerificationBtn {
  position: relative;
  border-radius: 48px;
  box-shadow: var(--btn-drop-shadow);
  background-color: #ffffff;
  font-size: 40px;
  letter-spacing: -2px;
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.ageVerificationBtn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.ageVerificationBtn:active {
  transform: scale(0.9);
}

.Yes {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 96px;
  height: 96px;
  border: 6px solid var(--green);
  color: var(--green);
}

.No {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 96px;
  height: 96px;
  border: 6px solid var(--red);
  color: var(--red);
}

.ageVerifyContent {
  height: 100vh;
}

@media screen and (max-width: 1000px) {

  .ageVerifyLogo {
    height: 45px;
  }

  .areYouOverEighteen {
    height: 40px;
  }

  .ageVerificationImg {
    height: 274px;
  }

  .ageVerificationBtn{
    width: 66px;
    height: 66px;
    font-size: 30px;
  }
}

@media screen and (max-width: 539px) {

  .ageVerifivationButtons {
    height: 50vh;
    flex-wrap: wrap;
  }

  .areYouOverEighteen {
    height: 24px;
  }

  .ageVerificationImg {
    order: -1;
    height: 200px;
    width: 100%;
  }

}

.privacyLink {
  background-color: transparent;
  color: #007bff;
}

.privacyLink:hover {
  text-decoration: underline;
}
