.privacyPage {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.privacyPage .privacyHeaderLogo {
   height: 71px;
 }

.privacyPage .privacyHeaderLogo img {
  height: 41px;
  margin: 20px 0 0 10vw;
}

.privacyPageWrapper {
  padding: 54px 10vw 0 10vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.privacyPageWrapper ul li,
.privacyPageWrapper ol li {
  font-size: 18px;
  line-height: 28px;
  font-family: var(--second-fond);
}

.privacyPageWrapper ol li {
  list-style-type: lower-alpha;
}

.privacyHeader {
  font-size: 40px;
  color: var(--brand-blue);
  font-weight: bold;
  font-family: var(--second-fond);
  margin-bottom: 45px;
}

.privacyText {
  text-align: justify;
  font-size: 18px;
  line-height: 28px;
  color: var(--brand-blue);
  font-family: var(--second-fond);
}

@media screen and (max-width: 750px) {

  .privacyPageWrapper {
    padding: 0 8px;
    height: 84vh;
  }

  .privacyPage {
    height: 100vh;
  }

  .privacyHeader {
    font-size: 24px;
    margin: 90px 0 15px 0;
  }

  .privacyText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
  }

  .privacyPage .privacyPageWrapper a {
    margin: 0 0 100px 20px;
  }

}
