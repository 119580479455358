#continueModal {
  width: 100vw;
}

#continueModal .modal-dialog {
  max-width: 100%;
}

#continueModal .modal-content {
  border-radius: 40px;
  height: 480px;
  max-width: 672px;
}

.close-modal-button {
  align-self: start;
}

#continueModal .modal-content .close-modal-button {
  margin: 20px 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#continueModal .modal-content .profileDeleteModalBtns {
  margin: 10px;
}

.continueButton {
  box-shadow: var(--btn-drop-shadow);
  position: relative;
  background-color: var(--brand-orange);
  font-weight: 900;
  font-family: var(--main-font);
  color: white;
  height: 50px;
  width: 155px;
  border-radius: 40px;
  outline:none !important;
}

.continueButton:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.continueButton:active {
  transform: scale(0.9);
}

.continuePicture {
  height: 250px;
}

#continueModal .modal-content .profileDeleteModalBtns .continueButton:first-child {
  margin-right: 40px;
}

@media (max-width: 680px) {

  #continueModal .modal-dialog {
    width: 100vw;
    max-width: 100vw;
  }

  #continueModal .modal-content {
    border-radius: 40px;
    width: 95vw;
    min-height: 400px;
    max-height: 85vh;
  }

  #continueModal h1 {
    font-size: 26px;
  }

  #continueModal .modal-content .profileDeleteModalBtns {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #continueModal .modal-content .profileDeleteModalBtns .continueButton:first-child {
    margin: 0 0 20px 0;
  }

  .continuePicture {
    height: 110px;
  }

}
