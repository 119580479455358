.showNav {
  display: flex;
  justify-content: center;
  width:100vw;
}

.footer2 {
  height: 75vh;
  width:100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navList {
  display: flex;
  flex-direction: column;
  height: 25vh;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.logo-nav {
  width: 75vw;
  margin-top: 50px;
}

.roundLinksNav {
  width: 44px;
  height: 44px;
  margin-left: 32px;
  border-radius: 50%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  box-shadow: var(--btn-drop-shadow);
  margin-left: 0; 
}  

.roundLinksNav>img {
  margin: auto;
}

.roundLinks:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.slidesNavLinks {
  margin: auto;
  font-size: 25px;
  color: #818fa6;
  font-weight: 600;
  font-family: var(--main-font);
  text-align: center;
}

@media (max-width: 730px) {

  .email-subscription {
    margin-top: 3em;
    width: 70%;
  }

  .email-subscription-button {
    width: 30%;
  }

  .footerRight {
    width: 60%;
  }

}

.fullEmail {
  margin-bottom: 15vh;
}

.logo-nav {
  margin-bottom: 4vh;
}
