@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap');

:root {
  --brand-orange: #f25d0f;
  --brand-orange-light: #f25d0f90;
  --brand-blue: #1a1c3f;
  --brand-light-blue: #ecf3ff;

  --green: #21d862;
  --red: #ff5959;

  --main-font: "Lato", sans-serif;
  --second-fond: "Courier", monospace;

  --btn-drop-shadow: 0px 4px 6px rgba(26,28,63,0.2);
  --btn-drop-shadow-onhover: 0px 4px 6px rgba(26,28,63,0.4);
}

@font-face {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USSwaPGR_p.woff2)
    format('woff2');
}

@font-face {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh7USSwiPGQ.woff2)
    format('woff2');
}

@font-face {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjxAwXjeu.woff2)
    format('woff2');
}

@font-face {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wXg.woff2)
    format('woff2');
}

* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
}

@media(max-width: 750px) {

  #root {
    min-height: 100vh;
  }

}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: var(--main-font), sans-serif;
}

button {
  outline: none;
  border: none;
}

button:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.upload-btn {
  box-shadow: var(--btn-drop-shadow);
  position: relative;
}

.upload-btn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.upload-btn:active {
  transform: scale(0.9);
}

@media only screen and (max-width: 1230px) {

  footer {
    padding: 0 10px;
    justify-content: space-evenly;
  }

  .footerRight {
    margin: auto 0;
  }

  .navLinks {
    padding-right: 15px;
  }

  .navFirst {
    padding-left: 15px;
  }

  .roundLinks {
    margin-left: 10px;
  }

  .fullEmail {
    max-width: 170px;
  }

  .fullEmailOrange {
    width: 70px;
  }

  .fullEmailText {
    width: 100px;
  }
}
@media (max-width: 800px) {
  .navFirst {
    padding-left: 15px;
  }
}

@media (max-width: 373px) {
  .card {
    margin: 0 auto;
  }
}

button {
  outline:none !important;
}
