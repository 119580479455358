.faqContainer {
  height: 80vh;
  overflow-y: scroll;
}

.faqContainer .header img {
  height: 41px;
}

.faqHeader {
  font-size: 40px;
  color: var(--brand-blue);
  font-weight: bold;
  font-family: var(--second-fond);
  text-align: center;
  margin-bottom: 40px;
}

.faqWrapper {
  width: 100%;
}

.questionsContiner {
  margin-left: 20%;
  width: 60%;
  display: flex;
  flex-direction: column;
}

.questionContiner {
  border-bottom:  1px solid var(--brand-blue);
  margin-bottom: 10px;
}

.questionContiner p {
  font-size: 13px;
  line-height: 16px;
  color: var(--brand-blue);
  font-family: var(--second-fond);
}

.visibleArea {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.visibleArea p {
  font-weight: bold;
}

.questionContiner button {
  padding: 0 10px;
  border-radius: 50%;
  background-color: white;
  box-shadow: var(--btn-drop-shadow);
  font-size: 23px;
}

.questionContiner button:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.questionContiner button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 650px) {

  .faqContainer .header {
    margin: 10% 20%;
  }

 .faqContainer {
   height: 100vh;
   padding-bottom: 10vh;
 }

 .questionsContiner {
   margin: 0 8px;
   width: calc(100% - 16px);
 }

}
