.inp {
  width: 496px;
  height: 52px;
  line-height: 52px;
  padding: 0 30px 0 56px;
  border-radius: 26px;
  background-color: #ffffff;
  border: 1px solid #e4eeff;
  box-shadow: var(--btn-drop-shadow);
  margin-left: 3vw;
  background-repeat: no-repeat;
  background-position: 4% 52%;
  background-size: 13px;
  color: var(--brand-blue);
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp::-webkit-search-cancel-button {
  display: none;
}

.inp::-ms-clear {
  display: none;
}

.inp:focus {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.inp::placeholder {
  font-size: 12px;
  letter-spacing: 0px;
  color: #818fa6;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp::-webkit-input-placeholder {
  font-size: 12px;
  letter-spacing: 0px;
  color: #818fa6;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp::-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0px;
  color: #818fa6;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp:-ms-input-placeholder {
  font-size: 12px;
  letter-spacing: 0px;
  color: #818fa6;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp:-moz-placeholder {
  font-size: 12px;
  letter-spacing: 0px;
  color: #818fa6;
  font-weight: 500;
  font-family: var(--main-font);
}

.inp:focus {
  outline: 0;
}

@media only screen and (min-width: 831px) {
  .inp {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 830px) {
  .inp {
    width: 100%;
    height: 32px;
    line-height: 32px;
    margin: 0
  }
}
