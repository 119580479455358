.termsAndConditions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 80vh;
  animation: appear 1s forwards;
}

.termsAndConditions-disapear{
  animation: disapear 1s forwards;
}
.termsAndConditionsText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.termsAndConditions h2 {
  font-size: 30x;
  color: var(--brand-blue);
  font-weight: bold;
  font-family: "Gloria Hallelujah", cursive;
}

.privacyLink {
  font-size: 18px;
  letter-spacing: 1px;
  color: #1a1b40;
  font-weight: 400;
  font-family: var(--main-font);
  text-align: center;
  text-decoration: underline;
}

.termsAndConditionBtn {
  position: relative;
  border-radius: 68px;
  box-shadow: var(--btn-drop-shadow);
  background-color: white;
  font-size: 20px;
  letter-spacing: -2px;
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.termsAndConditionBtn:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.termsAndConditionBtn:active {
  transform: scale(0.9);
}

.Accept {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90px;
  height: 90px;
  border: 6px solid var(--green);
  color: var(--green);
}

.Reject {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90px;
  height: 90px;
  border: 6px solid var(--red);
  color: var(--red);
}

.termsAndConditionsButtons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 65%;
}

@media screen and (max-width: 900px) {

  .termsAndConditions {
    height: 100vh;
    padding: 0 3vw;
  }

  .termsAndConditionsButtons {
    flex-wrap: wrap;
  }

  .termsAndConditioImg {
    order: -1;
    width: 100%;
  }

  .termsAndConditions h2 {
    font-size: 42px;
    text-align: center;
  }

  .termsAndConditionBtn {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 680px) {

  .termsAndConditions h2 {
    font-size: 34px;
    text-align: center;
  }

}

@media screen and (max-width: 414px) {

  .termsAndConditionBtn {
    margin-top: 20px;
  }

}
