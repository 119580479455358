.commitDeletionPage {
  height: 80vh;
}

.commitDeletionPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  text-align: justify;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  font-family: var(--second-fond);
}

.deleteBtn {
  position: relative;
  background-color: #be1c1c;
  font-family: inherit;
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
  color: white;
  padding: 15px;
  border-radius: 30px;
  margin-top: 30px;
}

.deleteBtn:hover {
  box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.5);
}

.deleteBtn:active {
  transform: scale(0.9);
}
