.feedbackContainer {
  height: 80vh;
}

.feedbackHeader {
  font-size: 36px;
  color: var(--brand-blue);
  font-family: var(--second-fond);
  text-align: center;
  margin-top: 2vh;
}

.feedBackButton {
  position: relative;
  width: 116px;
  height: 62px;
  border-radius: 36px;
  box-shadow: var(--btn-drop-shadow);
  background-color: var(--brand-orange);
  font-size: 17px;
  color: #ffffff;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
}

.backModalBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 70vw;
}

.feedBackButton:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.feedBackButton:active {
  transform: scale(0.9);
}

.cartF {
    background: white;
    width: 672px;
    height: 224px;
    padding: 36px 43px;
    border: 1px solid #E2E8F5;
    box-shadow: 0px 4px 3px rgba(26,28,63,0.1);
    border-radius: 40px;
    overflow-y: hidden;
    outline: none;
    color: var(--brand-blue);
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;
    font-family: var(--main-font);
}

.errorMessage {
  font-size: 13px;
  line-height: 16px;
  color: var(--red);
  font-family: var(--second-fond);
}

.feedbackMobileFooter {
  display: none;
}

@media(max-width: 715px) {

  .feedbackContainer {
    height: calc(100vh - 76px)
  }

  .feedbackMobileFooter {
    position: absolute;
    z-index: 399;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 76px;
    background-color: white;
    box-shadow: 0px -2px 14px rgba(26, 28, 63, 0.2);
    padding: 0 8px;
  }

  .feedbackContainer .backModalBtnContainer {
    display: none;
  }

  .feedbackHeader {
    font-size: 28px;
    width: calc(100vw - 16px);
  }

  .feedBackButton {
    display: none;
  }

  .cartF {
    width: calc(100vw - 16px);
    height: 40vh;
  }

  .feedBackMobileButton {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    box-shadow: var(--btn-drop-shadow);
    background-color: #ecf3ff;
  }

  .feedBackMobileButton:hover {
    box-shadow: var(--btn-drop-shadow-onhover);
  }

  .feedBackMobileButton:active {
    transform: scale(0.9);
  }

}
