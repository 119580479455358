.close-modal-button {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  box-shadow: var(--btn-drop-shadow);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#myModal .modal-content .close-modal-button {
  margin: 20px 0 0 20px;
}

.close-modal-button:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

#myModal {
  width: 100vw;
}

#myModal .modal-dialog {
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

#myModal .modal-content {
  border-radius: 40px;
  align-self: center;
  width: 672px;
  height: 540px;
}

.modal-content .arrow {
  height: 160px;
  align-self: center;
}

.modal-h1 {
  font-size: 26px;
  color: var(--brand-blue);
  font-weight: 700;
  font-family: var(--main-font);
  text-align: center;
}

.modal-p {
  font-size: 26px;
  color: var(--brand-blue);
  font-family: var(--main-font);
  text-align: center;
}

.modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--brand-orange);
  font-size: 26px;
  font-weight: 900;
  font-family: var(--main-font);
  box-shadow: var(--btn-drop-shadow);
  color: white;
  height: 84px;
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  outline:none !important;
}

.modal-button:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.modal-button:active{
  transform: scale(0.9);
}

@media(max-width: 750px) {

  #myModal .modal-dialog {
    max-width: 100vw;
  }

  #myModal .modal-content {
    width: 95vw;
    min-height: 400px;
    max-height: 85vh;
  }

}

@media(max-height: 570px) {

  #myModal .modal-h1 {
    font-size: 30px;
  }

  #myModal .modal-p {
    font-size: 20px;
  }

  #myModal img {
    height: 100px;
  }

  .modal-button {
    height: 54px;
    width: 54px;
    font-size: 20px;
  }

}
