.reg {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registarationHeader {
  margin-top: 12px;
  width: 80%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.registarationHeader .logo {
  height: 41px;
}

.btn-save {
  background: var(--brand-orange);
  border-radius: 26px;
  width: 148px;
  height: 52px;
  margin: 5px;
  font-family: var(--main-font);
  font-style: normal;
  color: white;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  float:right;
}

.btn-save-m {
  background: var(--brand-orange);
  border-radius: 28px;
  width: 152px;
  height: 52px;
  margin: 5px;
  font-family: var(--main-font);
  font-style: normal;
  color: white;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  float:right;
  display: none;
}

.registartionContent {
  position: relative;
  height: 65vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-height: 740px) and (min-width:  819px)  {

  .registartionContent {
    bottom: 50px;
  }

}

.formContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registartionInputs {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.registartionInputs img {
  height: 186px;
  width: 224px;
}

#upload-photo {
  letter-spacing: 0px;
  color: var(--brand-blue);
  font-weight: 500;
  font-family: var(--main-font);
}

#upload-photo::-webkit-file-upload-button {
  visibility: hidden;
}

#upload-photo::file-selector-button {
  visibility: hidden;
}

#upload-photo:focus {
  outline: 0;
}

.cred {
  width: 288px;
  height: 52px;
  line-height: 52px;
  border-radius: 26px;
  box-shadow: var(--btn-drop-shadow);
  background-color: #ffffff;
  border: 1px solid #e4eeff;
  font-size: 13px;
  padding-left: 18px;
  letter-spacing: 0px;
  color: var(--brand-blue);
  font-weight: 500;
  font-family: var(--main-font);
  margin-bottom: 11px;
}

.inputPlaceholder span {
  color: #ff0000;
}

.cred::placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
}

.cred::-webkit-input-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
}

.cred::-moz-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
}

.cred:-ms-input-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
}

.cred:-moz-placeholder {
  color: var(--brand-blue);
  font-family: var(--main-font);
}

.cred:focus {
  outline: 0;
}

.saveProfile {
  width: 288px;
  height: 52px;
  border-radius: 26px;
  box-shadow: var(--btn-drop-shadow);
  background-color: var(--brand-orange);
  font-size: var(--main-font);
  letter-spacing: 0px;
  color: #ffffff;
  font-weight: 800;
  font-family: var(--main-font);
  text-align: center;
}

.saveProfile:hover {
  box-shadow: var(--btn-drop-shadow-onhover);
}

.saveProfile:active {
  transform: scale(0.9);
}

 @media screen and (max-width: 876px)  {

   .registarationHeader {
     display: flex;
     justify-content: center;
   }

   .registarationHeader a img {
     height: 28px;
     width: 132px;
   }

   .registartionContent {
     height: 70vh;
   }

   .btnSaveMContainer {
     background-color: white;
     box-shadow: var(--btn-drop-shadow);
     width: 100vw;
     height: 10vh;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .btn-save-m {
     display: block;
     width: 40vw;
   }

   .avatarInput {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

   .registartionInputs {
     margin-top: 20px;
     align-items: center;
   }

   .cred {
     width: 100%;
   }

 }

.error {
  font-family: var(--main-font);
  color: var(--red);
  text-align: center;
}

.closeBtnWrapper {
  padding-top: 10px;
  width: 80%;
}

@media only screen and (max-width: 819px) {

  .reg {
    height: 100vh;
  }

  .registartionContent {
    margin-top: 15vh;
  }

  .registartionInputs {
    height: 100%;
  }

  .registartionInputs img {
    display: none;
  }

  .closeBtnWrapper {
    position: absolute;
    padding-top: 10px;
    width: 100%;
  }

  .closeBtnWrapper .buyCloseBtn {
    margin: 0 8px;
  }

}
