.startHome {
  padding: 0 10vw;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: 90% 40%;
  background-size: 65%;

  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (min-width: 950px) {

  .startHome {
    height: 80vh;
  }

}

.homeLogoMobile {
  display: none;
}

.homeImgWrapper {
  align-self: flex-end;
}

.homeImgWrapper img {
  width: 531px;
  height: 536px;
}

.homeDescription {
  padding: 20px 0;
  max-width: 469px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeDescription img {
  height: 50px;
  width: 230px;
}

.homeDescription p {
  margin-top: 25px;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  color: var(--brand-blue);
  font-weight: 400;
  font-family: "Gloria Hallelujah", cursive;
  text-align: center;
}

.homeDescription .homeButtonsContainer {
  height: 123px;
  width: 196px;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.homeDescription .homeButtonsContainer .home-button {
  width: 196px;
  height: 56px;
  border-radius: 28px;
  background-color: var(--brand-orange);
  font-size: 16px;
  color: white;
  font-weight: 900;
  font-family: var(--main-font);
  text-align: center;
}

@media screen and (min-width: 1000px) and (max-height: 769px) {


  .homeDescription img {
    margin-top: 100px;
  }

  .homeDescription p {
    font-size: 12px;
  }

}



/* MOBILE STYLES */

@media screen and (max-width: 900px) {

  .startHome {
    /*height: 100vh;*/
    padding: 0 2vw;
    margin: 0;

    background-position: 70% 60%;
    background-size: 150%;

    flex-direction: column;
    align-items: center;
  }

  .homeLogoMobile {
    display: block;
    height: 28px;
    width: 132px;
  }

  .homeImgWrapper {
    width: 96vw;

    align-self: flex-end;

    display: flex;
    justify-content: center;
  }

  .homeImgWrapper img {
    width: 198px;
    height: 199px;
  }

  .homeDescription img {
    display: none;
  }

}

@media screen and (max-width: 400px) and (max-height: 641px) {

  .homeLogoMobile {

  }

}
