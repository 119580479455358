.bigImage {
  display: none;
}

@media (max-width: 715px) {
  .bigImage {
    display: block;
  }
  .bigImage img {
    width: 100vw;
    max-height: 60vh;
    height: 40vh;
  }
}
