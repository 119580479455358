@media (min-width: 750px) {
  .profileContainer {
    height: 80vh;
  }
}

.profileContainer .header img {
  height: 41px;
}

.page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ProfilePageContent {
    width: 100vw;
    height: 57vh;
    min-height: 466px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .profileEmail {
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--brand-blue);
    font-weight: 500;
    font-family: var(--main-font);
  }

  .upload-btn {
    width: 204px;
    background: var(--brand-orange);
    border-radius: 28px;
    padding: 16px 22px;
    border: none;
    color: white;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .profile-page-btn {
    position: relative;
    width: 244px;
    height: 52px;
    border-radius: 26px;
    box-shadow: var(--btn-drop-shadow);
    background-color:  var(--brand-orange);
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: 800;
    font-family: var(--main-font);
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .avatar-control-panel {
    margin-top: 75px;
  }

  .avatar-panel {
    margin: auto;
    max-width: 100px;
    margin-top: 50px;
  }

  .profile-page-label {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center;
    width: 244px;
    height: 52px;
    border-radius: 26px;
    box-shadow: var(--btn-drop-shadow);
    background-color:  var(--brand-orange);
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: 800;
    font-family: var(--main-font);
    text-align: center;
  }

  .profile-page-label:hover {
    box-shadow: var(--btn-drop-shadow-onhover);
  }

  .profile-page-label:active {
    transform: scale(0.9);
  }

  .profile-page-btn:hover {
    box-shadow: var(--btn-drop-shadow-onhover);
  }

  .profile-page-btn:active {
    transform: scale(0.9);
  }

  .profile-page-btn.logout {
    width: 244px;
    height: 52px;
    border-radius: 26px;
    background-color: #e4eeff;
    font-size: 15px;
    line-height: 52px;
    color: var(--brand-blue);
    font-weight: 800;
    font-family: var(--main-font);
  }

  .profile-page-btn.edit {
    width: 244px;
    height: 52px;
    border-radius: 26px;
    font-size: 15px;
    line-height: 52px;
    font-weight: 800;
    font-family: var(--main-font);
  }

  .profile-page-btn.logout img {
    margin-right: 11px;
  }

  .profile-page-btn.refresh {
    width: 144px;
    height: 32px;
  }

  .profile-page-btn.delete-btn {
    width: 144px;
    height: 36px;
    border-radius: 18px;
    background-color: #ffcfcf;
    font-size: 15px;
    color: #ff0000;
    font-weight: 800;
    font-family: var(--main-font);
    box-shadow: none;
    margin-top: 20px;
  }

  .form-input {
    background: #ffffff;
    border: 1px solid #e4eeff;
    box-shadow: 0px 4px 6px rgba(26, 28, 63, 0.1);
    border-radius: 26px;
    padding: 22px 100px 22px 16px;
    margin-left: 16px;
    -webkit-appearance: none;
    outline: none;
    margin-bottom: 20px;
  }

  .margin-bottom-31 {
    margin-bottom: 31px;
  }

  .form-body {
    display: flex;
    width: 70vw;
    justify-content: space-between;
    align-items: center;
  }

  #profileDeleteModal .modal-content {
    height: 280px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 40px;
  }
  
  #profileEditImageModal .modal-content {
    margin: 0 auto;
    margin-bottom: 70px;
    height: 480px;
    width: 400px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 40px;
  }

  .modal-edit-avatar {
    margin: 0 auto;
  }

  #profileDeleteModal .modal-content .close {
    align-self: flex-end;
  }

  #profileDeleteModal .modal-content p {
    font-family: var(--main-font);
    text-align: center;
    color: var(--red);
  }

  .profileDeleteModalBtns {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .avatarUploadContainer {
    display: flex;
    flex-direction: column;
    width: 244px;
    height: 52px;
  }

  .avatarInput {
    width: 244px;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 26px;
    background-color: var(--brand-orange-light);
    margin-bottom: 0;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    font-weight: 800;
    font-family: var(--main-font);
  }

  #upload-profile-photo {
    display:none;
  }

  #upload-profile-photo::-webkit-file-upload-button {
    visibility: hidden;
  }

  #upload-profile-photo::file-selector-button {
    visibility: hidden;
  }

  #upload-profile-photo:focus {
    outline: 0;
  }

  .profile-page-btn.upload {
    /* height: 26px; */
    /* border-bottom-left-radius: 26px;
    border-bottom-right-radius: 26px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; */
  }

  .profile-page-btn.upload:active {
    transform: scale(1);
  }

  .emailVerifyMessage {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .emailVerifyH3  {
    width: 90vw;
    color: var(--red);
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
  }
  .refresh {
    width: 144px;
    height: 32px;


  }
  .emailVerifyBtn {
    background: #e4eeff;
    border-radius: 28px;
    border: none;
    color: var(--brand-blue);
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    box-shadow: none;
    margin-bottom: 10px;
  }

  @media (max-width: 650px) {

    .ProfilePageContent {
      height: 85vh;
      max-height: 496px;
    }

    .emailVerifyH3 {
      font-size: 13px;
    }

    .profileContainer .header {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;
    }

    .profileContainer .header a img {
      height: 28px;
      width: 132px;
    }

  }
