#educationalOverlay {
  position: absolute;
  z-index: 90;
  height: 80vh;
  width: 100vw;
  animation: colorchange-educational-overlay 7s ease-in-out;
  -webkit-animation: colorchange-educational-overlay 7s ease-in-out;
}

@keyframes colorchange-educational-overlay {
  0%    {background-color: transparent;}
  60%    {background-color: rgba(26,28,63, 0.6);}
  100%  {background-color: transparent;}
}

@-webkit-keyframes colorchange-educational-overlay {
  0%    {background-color: transparent;}
  60%    {background-color: rgba(26,28,63, 0.6);}
  100%  {background-color: transparent;}
}

@media(max-width: 715px) {

  #educationalOverlay {
    height: 91vh;
  }

}

.xarrowWrapper {
  animation: colorchange-xarrow-wrapper 3.5s ease-in;
  -webkit-animation: colorchange-xarrow-wrapper 3.5s ease-in;
  opacity: 0;
}

@keyframes colorchange-xarrow-wrapper {
  0%    {opacity: 0;}
  60%  {opacity: 1;}
  100%  {opacity: 0;}
}

@-webkit-keyframes colorchange-xarrow-wrapper {
  0%    {opacity: 0;}
  60%  {opacity: 1;}
  100%  {opacity: 0;}
}
